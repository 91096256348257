import { useLogout } from '@/services'
import { AxiosError, RawAxiosRequestHeaders } from 'axios'
import { identity } from 'ramda'
import { useEffect } from 'react'
import { createMultiTenantApi, useModal } from '../utils'

const tokenKey = 'AUTH_TOKEN'

export const Error403 = {
  not_verified: 'not_verified',
  exceeded_onboarding_quota: 'exceeded_onboarding_quota',
} as const

export const getTokenFromLocalStorage = () => localStorage.getItem(tokenKey)
export const storeToken = (token: string) => localStorage.setItem(tokenKey, token)

const makeAuthorizationHeader = (): RawAxiosRequestHeaders => {
  const token = getTokenFromLocalStorage()

  if (token)
    return {
      Authorization: `Bearer ${token}`,
    }

  return {}
}

export const api = createMultiTenantApi(
  import.meta.env.VITE_BACKEND_URL as string,
  makeAuthorizationHeader,
)

export const useSetErrorInterceptor = (onAccessDenied: (type: keyof typeof Error403) => void) => {
  const readyState = useModal(false)
  const logout = useLogout()

  const unauthorizedInterceptor = (error: AxiosError<{ type?: keyof typeof Error403 }>) => {
    if (error.response?.status === 401) logout()
    if (
      error.response?.status === 403 &&
      error.response.data?.type &&
      error.response.data.type in Error403
    ) {
      onAccessDenied(error.response.data.type)
    } else throw error
  }

  useEffect(() => {
    const interceptor = api.interceptors.response.use(identity, unauthorizedInterceptor)
    readyState.open()

    return () => {
      api.interceptors.response.eject(interceptor)
    }
  }, [])

  return readyState.isOpen
}
