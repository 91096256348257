import { Alert } from '@/components'
import { useAuthContext } from '@/providers'
import { paths } from '@/routes/paths'
import { useMeetingPrompt } from '@/services'
import { useNavigateWithFromState } from '@/utils'
import { Trans, useTranslation } from 'react-i18next'

const useMessages = () => {
  const { t } = useTranslation()

  return {
    title: t('virtualVisitPrompt.title'),
    cancel: t('global.cancel'),
    confirm: t('virtualVisitPrompt.confirm'),
    content3: t('virtualVisitPrompt.content3'),
  }
}

export const MeetingPrompt = ({ onClose }: { onClose: () => void }) => {
  const messages = useMessages()
  const navigate = useNavigateWithFromState()
  const { currentPatient } = useAuthContext()
  const { data, isLoading } = useMeetingPrompt(currentPatient?.id)

  const handleConfirm = () => {
    navigate(`/${paths.home}/${currentPatient?.id}/${paths.videoCall}`)
  }

  if (!isLoading && data?.status === 'in_progress')
    return (
      <Alert
        title={messages.title}
        contents={[
          <Trans
            i18nKey="virtualVisitPrompt.content1"
            values={{
              patient_full_name: `${currentPatient?.first_name} ${currentPatient?.last_name}`,
            }}
          />,
          <Trans
            i18nKey="virtualVisitPrompt.content2"
            values={{
              participantsCount: data.users_in_call,
            }}
          />,
          messages.content3,
        ]}
        actions={[
          { text: messages.confirm, onClick: handleConfirm },
          { text: messages.cancel, onClick: onClose, variant: 'secondary' },
        ]}
      />
    )

  return null
}
